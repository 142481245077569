// Body
$body-bg: #ffffff;
$secondary-bg: #f5f5f5;
$primary: #000000;
$secondary: #d8a238;
$success: #1bcc5a;
$gray-primary: #1d1d1d;
$gray-secondary: #707070;
$border: #707070;
$menu-trans: #ffffff;
$disabled: #666666;
$danger: #ff0000;
$discount-color: #CBCBCB;

// Typography
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
.text-white{
    color: #ffffff;
}
.bg-success{
    background-color: $success;
}
.bg-secondary{
    background-color: $secondary;
}
.bg-primary{
    background-color: $primary;
}
.text-gray-primary {
    color: $gray-primary;
}
.text-success {
    color: $success;
}
.text-gray-secondary {
    color: $gray-secondary;
}
