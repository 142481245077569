@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,900;1,500&display=swap');
@import '../../variables';
@media screen and (min-width: 768px) {
    #menu-nav {
        width: 100%;
        top: 0;
        position: fixed;
        left: 0;
        z-index:1000;
        transition: .3s;
        #shopping-cart-badge-container{
            &:hover #shopping-cart-badge, 
            &.added-to-cart #shopping-cart-badge{
                background-color: $primary !important;
            }
            #shopping-cart-badge{
                position: relative;
                margin-left: 0%;
                margin-bottom: -10px;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                background-color: $secondary !important;
                color: #fff;
                font-size: 0.6rem;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translate(70%, -100%);
                transition: .3s;
                z-index: 11;
            }
            #shopping-cart-list{
                position: absolute;
                display: none;
                padding: 10px 5px;
                top: 0;
                right: 0;
                width: 25vw;
                max-height: 40vh;
                min-width: 400px;
                z-index: 10;
                transform: translate(-3%, 8vh);
                background-color: rgba(255, 255, 255, 0.98) !important;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                .shopping-cart-list-item{
                    margin: 0 0 10px 0;
                    padding: 0 0 10px 0;
                    display: block;
                    width: 100%;
                    .shopping-cart-list-image-container,
                    .shopping-cart-list-name,
                    .shopping-cart-list-quantity,
                    .product-remove-button{
                        display: inline-block;
                        vertical-align: middle;
                        
                    }
                    .shopping-cart-list-image-container{
                        width: 4vw;
                        height: 6vw;
                        background-position: center;
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        margin-right: 10px;
                    }
                    .shopping-cart-list-name{
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        width: 40%;
                        letter-spacing: 0.3px;
                        color: $primary !important;
                    }
                    .shopping-cart-list-quantity{
                        padding: 0 10px;
                        border: 2px solid $gray-secondary;
                        font-size: 12px;
                        width: 30%;
                        .product-quantity-input{
                            border: none;
                            padding: 0;
                            font-size: 12px;
                        }
                    }
                    .product-remove-button{
                        font-size: 14px;
                        margin: 0 0 0 3%;
                        padding: 0;
                        cursor: pointer;
                        border: none;
                        background-color: transparent;
                        color: $primary;
                        transition: .3s;
                        color: #000;
                        &:hover{
                            color: $danger;
                        }
                    }
                }
            }
        }
        #menu-contact {
            background-color: #000;
            color: #ffffff;
            padding-top: 2px;
            padding-bottom: 1px;
        }
        #emergent-menu{
            background-color: #fff;
            width: 100vw;
            min-height: 30vh;
            position: relative;
            top: 1vh;
            left: 50%;
            transform: translate(-50%, 0);
            box-shadow: 0px 3px 6px #00000029;
            &.nav-list-close{
                display: none;
            }
            &.nav-list-open{
                display: flex;
            }
            #emergent-menu-title {
                letter-spacing: 3.2px;
                font-size: 1.95rem;
            }
            #emergent-menu-content-1{
                .emergent-list{
                    &.emergent-categories-list{
                        display: inline-block;
                        vertical-align: top;
                        width: 33%;
                    }
                    transition: .3s;
                    list-style: none;
                    &:hover .emergent-list-category{
                        color: $secondary;
                    }
                    .emergent-list-category,
                    .emergent-list-sub-category{
                        text-decoration: none;
                        font-size: 0.7rem;
                        cursor: pointer;
                        color: $gray-primary;
                        transition: .3s;
                        &:hover{
                            color: $secondary;
                        }
                    }
                    .emergent-list-category{
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 1.68px;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        gap: 5px;
                        
                    }
                    .emergent-list-sub-category{
                        font-weight: 200;
                        letter-spacing: 1.68px;
                    }
                    &.emergent-brand-list{
                        display: block;
                        width: 100%;
                        .emergent-brand-list-item{
                            display: inline-block;
                            vertical-align: top;
                            width: 33%;
                            cursor: pointer;
                            transition: .3s;
                            &:hover .emergent-brand-list-item-text{
                                color: $secondary;
                            }
                            .emergent-brand-list-item-text{
                                transition: .3s;
                                text-decoration: none;
                                color: $primary;
                                font-weight: 200;
                                letter-spacing: 1.68px;
                                font-size: 0.8rem;
                            }
                        }
                    }
                    
                }
            }
            #emergent-menu-content-2{
                #emergent-menu-category-image{
                    max-width: 100%;
                    max-height: 30vh;
                    margin: 0 0 0 auto;
                    padding: 0;
                    display: block;
                }
                #emergent-menu-brand-image{
                    width: 60%;
                    min-width: 100px;
                    max-width: 250px;
                    margin: 0 0 0 auto;
                    padding: 0;
                    display: block;
                }
            }
            
        }
        &.menu-white {
            background-color: #ffffff;
            color: $gray-primary;
            #nav-search {
                border-bottom: 2px solid $gray-primary;
                input {
                    &::placeholder {
                        color: $gray-primary;
                    }
                    
                }
            }
            #ul-nav {
                .nav-list {
                    color: $gray-primary;
                    
                }
            }
            #nav-user-bag {
                color: $gray-primary;
            }
        }
        &.menu-transparent {
            background-color: transparent;
            color: $menu-trans;
            #nav-search {
                border-bottom: 2px solid $menu-trans;
                input {
                    color: #fff;
                    &::placeholder {
                        color: $menu-trans;
                    }
                }
            }
            .header-logo {
                filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                cursor: pointer;
            }
            #ul-nav {
                .nav-list {
                    color: $menu-trans;
                    cursor: pointer;
                }
            }
            #nav-user-bag {
                #nav-opt-register{
                    filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                }
                #nav-opt-login {
                    filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                }
                .nav-opt{
                    color: $menu-trans;
                    img{
                        filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                    }
                    cursor: pointer;
                }
            }
            #nav-opt-register{
                color: #fff !important;
            }
        }
        .icon {
            display: inline-block;
            width: fit-content;
            margin-left: 3rem;
            cursor: pointer;
        }
        
        .icon img {
            height: 0.8rem;
        }
        #nav-hours h6 {
            margin-right: 4rem;
            margin-bottom: 0;
            display: inline-block;
            font-size: 0.6rem;
            font-weight: 400;
        }
        .nav-list {
            display: inline-block;
            width: fit-content;
            margin-right: 1.2rem;
            margin-left: 1.2rem;
            font-size: 0.6rem;
            font-weight: 400;
            cursor: pointer;
        }
        #nav-search {
            margin-top: 1rem;
            width: fit-content;
            input {
                width: 12rem;
                border: none;
                font-size: 0.6rem;
                font-weight: 400;
                background-color: transparent;
                &:focus {
                    outline: none;
                }
            }
        }
        #ul-nav {
            padding-bottom: 0.3rem;
        }
        .header-logo {
            display: block;
            margin: 0.8em auto;
            cursor: pointer;
            width: 105px;
        }
        #nav-user-bag {
            .nav-opt {
                height: 1.5rem;
                font-size: 1.5rem;
                margin-left: 2.4rem;
                margin-top: 1rem;
                cursor: pointer;
                #bag_img{
                    height: 1.5rem;
                    vertical-align: top;
                }
            }
            #nav-opt-register{
                color: #000 !important;
            }
        }
    }
    #general-footer{
        background-color: #F4F0EA;
        padding-left: 0;
        padding-right: 0;
        #newsletter-container{
            background-color: #F4F0EA;
            #newsletter-space{
                display: flex;
                margin: 2rem auto;
                justify-content: center;
                align-items: center;
                width: 100%;
                #newsletter-title{
                    width: 22%;
                    margin-right: 2em;
                    h5{
                        font-size: 1.2em;
                        font-weight: 400;
                        text-align: right;
                        margin-bottom: 0;
                    }   
                }
                #newsletter-mail{
                    width: 25%;
                    border-bottom: 2px solid #1d1d1d;
                    margin-left: 0;
                    margin-right: 0;
                    #newsletter-input{
                        border: none;
                        font-size: 0.8rem;
                        font-weight: 400;
                        background-color: transparent;
                        width: 100%;
                    }
                    #newsletter-button{
                        border-radius: 0;
                        color: #ffffff;
                        background-color: #000;
                        border: none;
                        padding: 4px 20px;
                        margin: 0;
                        letter-spacing: 1px;
                        font-size: 0.9em;
                    }
                }
            }
        }
        #footer-content{
            background-color: #ffffff;
            padding: 6rem 0;
            #footer-logo{
                width: 20%;
            }
            #footer-map-container{
                width:58%;
                margin-left: 10%;
            }
            #footer-map-container{
                .map-link{
                    font-weight: 500;
                    text-decoration: none;
                    cursor: pointer;
                    transition: .3s;
                    font-size: 0.7rem;
                    letter-spacing: 1.5px;
                    &:hover{
                        color: $secondary;
                    }
                }
            }
            #footer-legal-container{
                .legal-doc-link{
                    font-weight: 500;
                    text-decoration: none;
                    cursor: pointer;
                    transition: .3s;
                    font-size: 0.7rem;
                    letter-spacing: 1.5px;
                    &:hover{
                        color: $secondary;
                    }
                }
            }
            #footer-address{
                #footer-address-title{
                    font-weight: 500;
                    text-decoration: none;
                    font-size: 0.7rem;
                    letter-spacing: 1.5px;
                }
                .footer-address-content{
                    font-size: 0.7rem;
                    letter-spacing: 1px;
                    &#footer-address1-content{
                        margin: 0 0 5px 0;
                    }
                }
            }
            #footer-social-media{
                .footer-sm-img{
                    cursor: pointer;
                    filter: invert(100%);
                    height: 20px;
                    margin: 0;
                    &.footer-sm-img-border{
                        border-left: 1px solid $gray-primary;
                        padding: 0 50px;
                    }
                    &.footer-sm-img-first{
                        padding-right:50px;
                        padding-left: 12px;
                    }
                }
            }
        }
    }
    .products-list{
        list-style: none;
        vertical-align: middle;
        .products-list-item{
            cursor: pointer;
            transition: .3s;
            display: inline-block;
            vertical-align: top;
            width: 25vh;
            margin-left: 0.47rem;
            margin-right: 0.47rem;
            &:hover  .product-img{
                background-size: 105%;
            }
            .product-img{
                height:35vh;
                width: 25vh;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-size: 100%;
                transition: .3s;
                
            }  
            .product-name,
            .product-brand,
            .product-price,
            .product-normal-price{
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: center;
                color: $gray-secondary;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }   
            .product-price {
                font-weight: 500;
                font-size: 1.05em;
                letter-spacing: 0.5px;
                color: #1d1d1d;
            }
            .product-name {
                font-weight: 500;
                font-size: 1.2em;
                letter-spacing: 0.5px;
            }
            .product-normal-price{
                color: $discount-color !important;
                text-decoration: line-through;
                font-weight: 600;
                font-size: .8em;
                letter-spacing: 0.5px;
            }
            .product-brand{
                font-weight: 400;
                font-size: .9em;
                letter-spacing: 0.5px;
            }
        }
    }
    #login-container{
        z-index: 100;
        background-color: #fff;
        width: 600px;
        box-shadow: 0px 3px 6px #00000029;
        padding: 25px;
        text-align: center;
        color: $gray-secondary;
        display: none;
        margin-top: 8vh;
        #login-close-button{
            margin: 0;
            padding: 0;
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            border: none;
            background-color: transparent;
            font-size: 1.3em;
        }
        #login-image-container{
            margin: 0 auto 2vh auto;
            padding: 40px;
            border-radius: 50%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            width: min-content;
            display: block;
            #login-image{
                width: 70px;
            }
        }
        #login-title{
            margin: 0 0 2vh;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
        }
        .input-group{
            padding: 0 0 2vh 0;
            margin: 0 auto;
            width: 50%;
            letter-spacing: 0.5px;
            span{
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                text-align: left;
            }
            .form-control{
                border: none;
                border-bottom: 2px solid $gray-secondary;
                padding: 2px 0;
                border-radius: 0;
            }
        }
        #login-button{
            width: 50%;
            margin: 1vh 0;
            letter-spacing: 0.5px;
        }
        #login-register-forgot-container{
            margin: 2vh 0 0 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
            span, a{
                margin: 0;
                padding: 0 1%;
                cursor: pointer;
                transition: .3s;
                text-decoration: none;
                &:hover{
                    color: $secondary !important;
                }
            }
            #login-register{
                font-weight: 600;
                color: #000;
                letter-spacing: 0.5px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #menu-nav {
        width: 100vw;
        top: 0;
        position: fixed;
        left: 0;
        z-index:1000;
        padding: 2vh 0 !important;
        transition: .3s;
        #burger-menu{
            font-size: 24px;
            cursor: pointer;
        }
        #shopping-cart-badge-container{
            margin: auto 0;
            &:hover #shopping-cart-badge, 
            &.added-to-cart #shopping-cart-badge{
                background-color: $primary !important;
            }
            #shopping-cart-badge{
                position: relative;
                top: -25%;
                right: 0;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                background-color: $secondary !important;
                color: #fff;
                font-size: 0.6rem;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translate(70%, -100%);
                transition: .3s;
                z-index: 11;
            }
            #shopping-cart-list{
                position: absolute;
                display: none;
                padding: 10px 5px;
                top: 0;
                right: 0;
                width: 25vw;
                max-height: 20vh;
                width: 90%;
                z-index: 10;
                transform: translate(-3%, 8vh);
                background-color: rgba(255, 255, 255, 0.98) !important;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                .shopping-cart-list-item{
                    margin: 0 0 10px 0;
                    padding: 0 0 10px 0;
                    display: block;
                    width: 100%;
                    .shopping-cart-list-image-container,
                    .shopping-cart-list-name,
                    .shopping-cart-list-quantity,
                    .product-remove-button{
                        display: inline-block;
                        vertical-align: middle;
                        
                    }
                    .shopping-cart-list-image-container{
                        width: 17vw;
                        height: 22vw;
                        background-position: center;
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        margin-right: 10px;
                    }
                    .shopping-cart-list-name{
                        margin: 0;
                        padding: 0;
                        font-size: 12px;
                        width: 40%;
                        letter-spacing: 0.3px;
                        color: $primary !important;
                    }
                    .shopping-cart-list-quantity{
                        padding: 0 10px;
                        border: 2px solid $gray-secondary;
                        font-size: 12px;
                        width: 30%;
                        .product-quantity-input{
                            border: none;
                            padding: 0;
                            font-size: 12px;
                        }
                    }
                    .product-remove-button{
                        font-size: 14px;
                        margin: 0 0 0 3%;
                        padding: 0;
                        cursor: pointer;
                        border: none;
                        background-color: transparent;
                        color: $primary;
                        transition: .3s;
                        color: #000;
                        &:hover{
                            color: $danger;
                        }
                    }
                }
            }
        }
        #menu-contact {
            background-color: #000;
            color: #ffffff;
            padding-top: 2px;
            padding-bottom: 1px;
            display: none !important;
        }
        #emergent-menu{
            background-color: #fff;
            width: 100vw;
            min-height: 30vh;
            position: relative;
            top: 1vh;
            left: 50%;
            transform: translate(-50%, 0);
            &.nav-list-close{
                display: none;
            }
            &.nav-list-open{
                display: flex;
            }
            #emergent-menu-title {
                letter-spacing: 3.2px;
                font-size: 1.95rem;
            }
            #emergent-menu-content-1{
                .emergent-list{
                    &.emergent-categories-list{
                        display: inline-block;
                        vertical-align: top;
                        width: 33%;
                    }
                    transition: .3s;
                    list-style: none;
                    &:hover .emergent-list-category{
                        color: $secondary;
                    }
                    .emergent-list-category,
                    .emergent-list-sub-category{
                        text-decoration: none;
                        font-size: 0.7rem;
                        cursor: pointer;
                        color: $gray-primary;
                        transition: .3s;
                        &:hover{
                            color: $secondary;
                        }
                    }
                    .emergent-list-category{
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 1.68px;
                        
                    }
                    .emergent-list-sub-category{
                        font-weight: 200;
                        letter-spacing: 1.68px;
                    }
                    &.emergent-brand-list{
                        display: block;
                        width: 100%;
                        .emergent-brand-list-item{
                            display: inline-block;
                            vertical-align: top;
                            width: 33%;
                            cursor: pointer;
                            transition: .3s;
                            &:hover .emergent-brand-list-item-text{
                                color: $secondary;
                            }
                            .emergent-brand-list-item-text{
                                transition: .3s;
                                text-decoration: none;
                                color: $primary;
                                font-weight: 200;
                                letter-spacing: 1.68px;
                                font-size: 0.8rem;
                            }
                        }
                    }
                    
                }
            }
            #emergent-menu-content-2{
                #emergent-menu-category-image{
                    max-width: 100%;
                    max-height: 30vh;
                    margin: 0 0 0 auto;
                    padding: 0;
                    display: block;
                }
                #emergent-menu-brand-image{
                    width: 60%;
                    min-width: 100px;
                    max-width: 250px;
                    margin: 0 0 0 auto;
                    padding: 0;
                    display: block;
                }
            }
            
        }
        &.menu-white {
            background-color: #ffffff;
            color: $gray-primary;
            #nav-search {
                border-bottom: 2px solid $gray-primary;
                input {
                    &::placeholder {
                        color: $gray-primary;
                    }
                    
                }
            }
            #ul-nav {
                .nav-list {
                    color: $gray-primary;
                    
                }
            }
            #nav-user-bag {
                color: $gray-primary;
            }
        }
        &.menu-transparent {
            background-color: transparent;
            color: $menu-trans;
            #nav-search {
                border-bottom: 2px solid $menu-trans;
                input {
                    color: #fff;
                    &::placeholder {
                        color: $menu-trans;
                    }
                }
            }
            .header-logo {
                filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                cursor: pointer;
            }
            #ul-nav {
                .nav-list {
                    color: $menu-trans;
                    cursor: pointer;
                }
            }
            #nav-user-bag {
                #nav-opt-register{
                    filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                }
                #nav-opt-login {
                    filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                }
                .nav-opt{
                    color: $menu-trans;
                    img{
                        filter: brightness(200%) contrast(200%) saturate(0%) invert(100%);
                    }
                    cursor: pointer;
                }
            }
            #nav-opt-register{
                color: #fff !important;
            }
        }
        .icon {
            display: inline-block;
            width: fit-content;
            margin-left: 3rem;
            cursor: pointer;
        }
        
        .icon img {
            height: 0.8rem;
        }
        #nav-hours h6 {
            margin-right: 4rem;
            margin-bottom: 0;
            display: inline-block;
            font-size: 0.6rem;
            font-weight: 400;
        }
        .nav-list {
            display: inline-block;
            width: fit-content;
            margin-right: 1.2rem;
            margin-left: 1.2rem;
            font-size: 0.6rem;
            font-weight: 400;
            cursor: pointer;
        }
        #nav-search {
            margin-top: 1rem;
            width: fit-content;
            input {
                width: 12rem;
                border: none;
                font-size: 0.6rem;
                font-weight: 400;
                background-color: transparent;
                &:focus {
                    outline: none;
                }
            }
        }
        #ul-nav {
            padding-bottom: 0.3rem;
        }
        .header-logo {
            display: block;
            margin: 0 auto;
            cursor: pointer;
            width: 25vw;
        }
        #nav-user-bag {
            .nav-opt {
                height: 1.5rem;
                font-size: 1.5rem;
                margin-left: 2.4rem;
                cursor: pointer;
                #bag_img{
                    height: 1.5rem;
                    vertical-align: top;
                }
            }
            #nav-opt-register{
                color: #000 !important;
                margin: auto;
            }
            #nav-opt-login{
                margin: auto 0;
            }
        }
        #responsive-menu{
            margin: 0;
            padding: 0 10px;
            width: 70%;
            height: 100vh;
            background-color: #FFF;
            position: fixed;
            display: none;
            top: 0;
            left: -100%;
            z-index: 100;
            -webkit-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
            box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
            #responsive-close-opt{
                margin: 0;
                padding: 0;
                display: block;
                background-color: transparent;
                cursor: pointer;
                position: absolute;
                top: 1%;
                right: 3%;
                border: none;
                font-size: 1.5em;
            }
            #responsive-header-logo{
                margin: 10% auto 0 auto;
                display: block;
                width: 45%;
            }
            #responsive-ul-nav{
                margin: 4vh 0 0 0;
                padding: 0;
                display: block;
                list-style: none;
                text-align: center;
                max-height: 80vh;
                .nav-list{
                    margin: 0 0 1vh;
                    padding: 0;
                    display: block;
                    width: 100%;
                    font-size: 1em;
                    font-weight: 400;
                    cursor: pointer;
                    color: $primary;
                    &:hover{
                        color: $secondary;
                    }
                    span{
                        font-size: 16px;
                        font-weight: 300;
                    }
                    .emergent-data{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        .emergent-list{
                            margin: 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            list-style: none;
                            .emergent-list-category{
                                text-decoration: none;
                                font-size: 14px;
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                            }
                            .emergent-list-item{
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                                .emergent-list-sub-category{
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                    text-decoration: none;
                                    font-size: 12px;
                                }
                                
                            }
                        }
                        
                    }
                }
            }
        }
    }
    #general-footer{
        background-color: #F4F0EA;
        padding-left: 0;
        padding-right: 0;
        #newsletter-container{
            background-color: #F4F0EA;
            #newsletter-space{
                display: block;
                margin: 2rem auto;
                justify-content: center;
                align-items: center;
                #newsletter-title{
                    width: 80%;
                    margin: 0 auto;
                    h5{
                        font-size: 1.2em;
                        font-weight: 400;
                        text-align: center;
                        margin-bottom: 1em;
                    }   
                }
                #newsletter-mail{
                    width: 92%;
                    border-bottom: 2px solid #1d1d1d;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    #newsletter-input{
                        border: none;
                        font-size: 0.8em;
                        font-weight: 400;
                        background-color: transparent;
                    }
                    #newsletter-button{
                        border-radius: 0;
                        color: #ffffff;
                        background-color: #000;
                        border: none;
                        padding: 4px 12px;
                        margin: 0;
                        letter-spacing: 1px;
                        font-size: 0.9em;
                    }
                }
            }
        }
        #footer-content{
            background-color: #ffffff;
            padding: 3rem 0;
            #footer-logo{
                display: none;
             }
             #footer-map-container{
                 width:100%;
                 margin-left: 0;
             }
             #footer-map-container{
                 .map-link{
                     font-weight: 500;
                     text-decoration: none;
                     cursor: pointer;
                     transition: .3s;
                     font-size: 0.7rem;
                     letter-spacing: 1.5px;
                     &:hover{
                         color: $secondary;
                     }
                 }
             }
             #footer-legal-container{
                 .legal-doc-link{
                     font-weight: 500;
                     text-decoration: none;
                     cursor: pointer;
                     transition: .3s;
                     font-size: 0.7rem;
                     letter-spacing: 1.5px;
                     &:hover{
                         color: $secondary;
                     }
                 }
             }
             #footer-address{
                 #footer-address-title{
                     font-weight: 500;
                     text-decoration: none;
                     font-size: 0.7rem;
                     letter-spacing: 1.5px;
                 }
                 .footer-address-content{
                     font-size: 0.7rem;
                     letter-spacing: 1px;
                     &#footer-address1-content{
                         margin: 0 0 1vh 0;
                     }
                 }
             }
             #footer-social-media{
                 .footer-sm-img{
                     cursor: pointer;
                     filter: invert(100%);
                     height: 20px;
                     margin: 0;
                     &.footer-sm-img-border{
                         border-left: 1px solid $gray-primary;
                         padding: 0 35px;
                     }
                     &.footer-sm-img-first{
                         padding-right: 35px;
                         padding-left: 12px;
                     }
                 }
             }
        }
    }
    .products-list{
        list-style: none;
        vertical-align: middle;
         .products-list-item{
            cursor: pointer;
            transition: .3s;
            display: block;
            width: 100%;
            margin: 0 !important;
            &:hover  .product-img{
                background-size: 105%;
            }
            .product-img{
                height:60vh;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-size: 100%;
                transition: .3s;
                margin: 0;
                display: block;
                margin: 0 auto !important;
            }  
            .product-name,
            .product-brand,
            .product-price,
            .product-normal-price{
                display: block;
                width: 100%;
                margin: 0;
                padding: 0;
                text-align: center !important;
                color: $gray-secondary;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }   
            .product-price {
                font-weight: 500;
                font-size: 1.05em;
                letter-spacing: 0.5px;
                color: #1d1d1d;
            }
            .product-name {
                font-weight: 500;
                font-size: 1.2em;
                letter-spacing: 0.5px;
            }
            .product-normal-price{
                color: $discount-color !important;
                text-decoration: line-through;
                font-weight: 600;
                font-size: .8em;
                letter-spacing: 0.5px;
            }
            .product-brand{
                font-weight: 400;
                font-size: .9em;
                letter-spacing: 0.5px;
            }
        }
    }
    #login-container{
        z-index: 100;
        background-color: #fff;
        width: 100%;
        height: 100vh;
        padding: 0 25px;
        text-align: center;
        color: $gray-secondary;
        display: none;
        margin-top: 10vh;
        #login-close-button{
            margin: 0;
            padding: 0;
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            border: none;
            background-color: transparent;
            font-size: 1.3em;
        }
        #login-image-container{
            margin: 10vh auto 2vh auto;
            padding: 40px;
            border-radius: 50%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            width: min-content;
            display: block;
            #login-image{
                width: 70px;
            }
        }
        #login-title{
            margin: 0 0 2vh;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
        }
        .input-group{
            padding: 0 0 2vh 0;
            margin: 0 auto;
            width: 90%;
            letter-spacing: 0.5px;
            span{
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                text-align: center;
            }
            .form-control{
                border: none;
                border-bottom: 2px solid $gray-secondary;
                padding: 2px 0;
                border-radius: 0;
                text-align: center;
            }
        }
        #login-button{
            width: 50%;
            margin: 1vh 0;
            letter-spacing: 0.5px;
        }
        #login-register-forgot-container{
            margin: 2vh 0 0 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
            span, a{
                margin: 0;
                padding: 0 1%;
                cursor: pointer;
                transition: .3s;
                text-decoration: none;
                &:hover{
                    color: $secondary !important;
                }
            }
            #login-register{
                font-weight: 600;
                color: #000;
                letter-spacing: 0.5px;
            }
        }
    }
}
